import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  logo: {
    height: 120
  }
});

function Copyright() {
  return (
    <Typography variant="body1" color="textSecondary" align="center">
      {'Coming soon.'}
    </Typography>
  );
}

export default function App() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box my={4} align="center" >
        <img src="logo.png" alt="Under Construction" className={classes.logo}/>
      </Box>
      <Box my={4}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Website Under Construction
        </Typography>
        <Copyright />
      </Box>
    </Container>
  );
}
